import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type DarkThemeContextType = {
  darkTheme: boolean;
  setDarkTheme: (value: boolean) => any;
};

const init = {
  darkTheme: false,
  setDarkTheme: () => null,
};

export const DarkThemeContext = createContext<DarkThemeContextType>(init);

export const DarkThemeContextProvider = (props: { children: ReactNode }) => {
  // const lsRef = useRef(false);
  // const [darkTheme, setDarkTheme] = useState(true);
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const isDark = JSON.parse(localStorage.getItem("darkTheme") || "false");
  //     setDarkTheme(isDark);
  //     lsRef.current = true;
  //     console.log("isDark", isDark);
  //   }
  // }, []);
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (darkTheme) {
  //       window.document.body.classList.add("dark");
  //     } else window.document.body.classList.remove("dark");
  //     if (lsRef.current)
  //       localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
  //   }
  // }, [darkTheme]);

  const [darkTheme, setDarkTheme] = useState<boolean>(() => {
    if (typeof window !== "undefined") {
      const storedTheme = localStorage.getItem("darkTheme");
      return storedTheme ? JSON.parse(storedTheme) : true;
    }
    return true;
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (darkTheme) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
      localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    }
  }, [darkTheme]);
  return (
    <DarkThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
      {props.children}
    </DarkThemeContext.Provider>
  );
};

export const useDarkTheme = () => useContext(DarkThemeContext);
